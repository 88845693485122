export const loadScript = async (sourceUrl: string) => {

    return new Promise((resolve, reject) => {

        if(sourceUrl.endsWith('.js')) {

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = sourceUrl;

            script.onload = () => resolve(null);
            script.onerror = () => reject(null);

            document.body.appendChild(script);

        } else if(sourceUrl.endsWith('.css')) {

            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = sourceUrl;

            document.head.appendChild(link);

            resolve(null);

        }

    });

}
