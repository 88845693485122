class Ps20LaunchEvent {

    // holds the event name
    public static NAME = 'ps20:launch';

    // holds the event to dispatch
    public readonly event: CustomEvent;

    /**
     *
     * @param event
     */
    constructor(event: CustomEvent) {
        this.event = event;
    }

    /**
     * Creates the event
     * @param eventData
     */
    static create(eventData: {element: HTMLElement}): Ps20LaunchEvent {

        // create the custom event
        const event = new CustomEvent(this.NAME, {
            detail: {
                ...eventData
            }
        })

        // launch the custom event
        return new Ps20LaunchEvent(event);

    }

    /**
     * Emits the event
     */
    public emit() {
        document.dispatchEvent(this.event);
    }

}

export default Ps20LaunchEvent;
