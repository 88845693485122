// creates the angular base href
export const injectBase = async (): Promise<any> => {
    return new Promise((resolve) => {

        // get the current href
        const base = window.location.href;

        // create the href
        const baseElement = document.createElement('base');
        baseElement.setAttribute('href', base);

        // append it
        document.head.appendChild(baseElement);

        // resolve the promise
        resolve(null);

    });
}
