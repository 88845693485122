import './polyfills';
import './launcher';
import Ps20LaunchEvent from './event/ps20-launch-event';
import './styles/index.scss'

// launches the app
const launch = () => {

    const elements = document.querySelectorAll('.ps20-calculator');

    const basicStyle = document.createElement('style');
    basicStyle.innerHTML = `
@keyframes ps20-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
    `
    document.head.appendChild(basicStyle);

    elements.forEach((element: HTMLElement) => {

        // create the launch event
        const launchEvent = Ps20LaunchEvent.create({
            element
        });

        // emit the launch event
        launchEvent.emit();

    });

};

if (document.readyState !== 'loading') {
    launch();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        launch()
    });
}
