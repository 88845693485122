import Ps20LaunchEvent from './event/ps20-launch-event';
import {injectBase} from './helpers/inject-base';
import {startLoadingScreen, stopLoadingScreen} from './helpers/loading-screen';
import {createAppRoot} from './helpers/create-root-element';
import {loadScript} from './helpers/load-script';

declare global {
    interface Window {
        ps20LauncherLaunchApp: (index: number) => Promise<string>
    }
}

const attachListener = () => {

    document.addEventListener(Ps20LaunchEvent.NAME, (event: CustomEvent) => {

        // check if dom element is attached
        if (!event.detail.element) {
            console.warn('received invalid launch event')
        }

        // create the launcher
        const launcher = Launcher.createFromElement(event.detail.element);

        // launch it
        launcher.launch();

    });

}

// attach the listener
if (document.readyState !== 'loading') {
    attachListener();
} else {
    document.addEventListener('DOMContentLoaded', () => attachListener());
}

export interface LauncherConfiguration {
    baseUrl: string
    // which app is the default
    defaultApp?: number
    // when to fallback when event is not fired which app to use
    defaultAppFallbackTimeout?: number
    // when active sets the spinner in the middle of the spa not the whole page
    spinnerHeightAdjustment: boolean
}

export interface EntryPoints {
    main: string;
    polyfills: string;
    runtime: string;
    styles: string;
    common?: string;
    options?: string[];
}

class Launcher {

    // holds the html element
    private readonly element: HTMLElement;
    private configuration: LauncherConfiguration;
    private fallbackTimeout: any = null;

    private readonly OPTIONS_FLAGS = {
        PREVENT_BUSINESS_CONFIG: "PREVENT_BUSINESS_CONFIG",
        PREVENT_BUSINESS_CONFIG_INIT: "PREVENT_BUSINESS_CONFIG_INIT",
        PREVENT_POLYFILL: "PREVENT_POLYFILL"
    }

    /**
     *
     * @param element
     */
    constructor(element: HTMLElement) {

        // assign the html element
        this.element = element;

        // parse the configuration
        this.extractConfiguration();

    }

    /**
     * Creates the launcher based on the html element
     * @param element
     */
    public static createFromElement(element: HTMLElement): Launcher {
        return new Launcher(element);
    }

    /**
     * Extracts the configuration based on the customconfiguration attribute
     */
    private extractConfiguration() {

        const configString = this.element.getAttribute('customConfiguration');
        try {
            const rawConfig = JSON.parse(configString);
            if (!rawConfig.baseUrl) {
                console.error('no base url configured', rawConfig)
                return
            }

            console.log(rawConfig)
            this.configuration = {
                baseUrl: rawConfig.baseUrl,
                defaultApp: (typeof rawConfig.defaultApp === 'string') ? parseInt(rawConfig.defaultApp) : 0,
                defaultAppFallbackTimeout: (typeof rawConfig.defaultAppFallbackTimeout === 'string') ? parseInt(rawConfig.defaultAppFallbackTimeout) : 1000,
                spinnerHeightAdjustment: !!rawConfig?.spinnerHeightAdjustment
            }
        } catch (e) {
            console.warn('Could parse calculator configuration', this.element);
        }
    }

    public async launch() {

        const rawBaseUrls = this.configuration.baseUrl.split(';;');
        if (rawBaseUrls.length > 1) {

            startLoadingScreen(this.configuration);

            window.ps20LauncherLaunchApp = async (index) => {
                if (rawBaseUrls.length < index || !rawBaseUrls[index]) {
                    throw new Error(`Base URL with index ${index} could not be found. Available: ${rawBaseUrls.join(', ')}`)
                }
                clearTimeout(this.fallbackTimeout)
                const requiredBaseUrl = rawBaseUrls[index];
                await this.launchFromUrl(requiredBaseUrl);
                console.debug(`PS20 Launcher`, requiredBaseUrl, 'launched');
                return requiredBaseUrl;
            }
            this.fallbackTimeout = setTimeout(async () => {
                await this.launchFromUrl(rawBaseUrls[this.configuration.defaultApp || 0]);
                console.debug(`PS20 Launcher`, rawBaseUrls[this.configuration.defaultApp || 0], 'launched as fallback');
            }, this.configuration.defaultAppFallbackTimeout || 1000);

            const availableIndexes = Array(rawBaseUrls.length).fill(0).map((value, index) => `window.ps20LauncherLaunchApp(${index}) for ${rawBaseUrls[index]}`)
            console.debug('PS20 Launcher waiting for following actions')
            for (const a of availableIndexes) {
                console.debug(a)
            }

        } else {
            await this.launchFromUrl(rawBaseUrls[this.configuration.defaultApp || 0]);
            console.debug(`PS20 Launcher`, rawBaseUrls[this.configuration.defaultApp || 0], 'launched');
        }

    }

    private async launchFromUrl(baseUrl: string) {

        window.ps20LauncherLaunchApp = async (index: number) => {
            console.debug(baseUrl, 'already launched')
            return baseUrl;
        }

        // start the loading screen
        startLoadingScreen(this.configuration);

        // create mandatory stuff
        const appRoot = await createAppRoot(this.element);
        await injectBase();

        // fetch the entry points
        const entryPoints = await this.fetchEntryPoints(baseUrl);

        if (!entryPoints.options) {
            entryPoints.options = [];
        }


        // static scripts
        if (!entryPoints.options.includes(this.OPTIONS_FLAGS.PREVENT_BUSINESS_CONFIG_INIT)
            && !baseUrl.includes("personalisierte-landingpage")) {
            await this.loadScript(baseUrl, 'assets/js/business-config-init.js', true);
        }
        if (!entryPoints.options.includes(this.OPTIONS_FLAGS.PREVENT_BUSINESS_CONFIG)
            && !baseUrl.includes("digitales-angebot")
            && !baseUrl.includes("personalisierte-landingpage")) {
            await this.loadScript(baseUrl, 'assets/js/business-config.js', true)
        }

        // app specific entries
        await this.loadScript(baseUrl, entryPoints.styles);
        await this.loadScript(baseUrl, entryPoints.runtime);

        if (!entryPoints.options.includes(this.OPTIONS_FLAGS.PREVENT_POLYFILL)) {
            await this.loadScript(baseUrl, entryPoints.polyfills);
        }
        if (entryPoints.common) {
            await this.loadScript(baseUrl, entryPoints.common);
        }
        await this.loadScript(baseUrl, entryPoints.main);

        setTimeout(() => {

            // make app root visible
            appRoot.style.display = '';

            // stop the loading screen
            stopLoadingScreen();

        }, 1500);
    }

    private async fetchEntryPoints(baseUrl: string): Promise<EntryPoints> {

        // get the current timestamp to disable caching
        const currentTimestamp = Date.now();

        try {
            return await fetch(`${baseUrl}/spa/entrypoints.json?v=${currentTimestamp.toString()}`, {
                method: 'GET'
            }).then(res => res.json()) as EntryPoints;
        } catch (e) {
            throw new Error(`Could not fetch entrypoints from ${baseUrl}`);
        }

    }

    private async loadScript(baseUrl: string, scriptSrc: string, addDateStamp: boolean = false) {
        return await loadScript(`${baseUrl}/spa/${scriptSrc}`);
    }

}

export default Launcher
