export const createAppRoot = async (parent: HTMLElement): Promise<HTMLElement> => {
    return new Promise(resolve => {

        const rootElement = document.createElement('app-root');
        rootElement.style.display = 'none';

        parent.appendChild(rootElement);
        resolve(rootElement);
    })
}
